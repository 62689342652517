<template>
  <!--  展示学校的页面-->
  <div class="school">
    <el-row class="p1">
     <p>
       教育部公布的信息显示，截至2020年6月30日，全国高等学校共计3005所，其中普通高等学校2740所,
       含本科院校1272所、高职(专科)院校1468所(不包含港澳台地区高等学校)。
     </p>
    </el-row>
    <el-form  :inline="true" label-position="left">
      <el-form-item label="省份">
        <el-select v-model="nowProvince" placeholder="请选择省" size="medium" @change="change_School(1)">
          <el-option
              v-for="item in province"
              :key="item.id"
              :label="item.disName"
              :value="item.disName">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="学校类别">
        <el-select v-model="level" placeholder="请选择学校类别" size="medium" @change="change_School(1)">
          <el-option label="全部" value="全部"></el-option>
          <el-option label="本科" value="本科"></el-option>
          <el-option label="专科" value="专科"></el-option>
          <el-option label="研究所" value="研究所"></el-option>
          <el-option label="成人高等学校" value="成人高等学校"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="学校等级">
        <el-select v-model="one" placeholder="请选择学校等级" size="medium" @change="change_School(1)">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="985" :value="9"></el-option>
          <el-option label="211" :value="2"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <el-row class="item">
      <el-tag><h1>学校数量{{ school.total }}</h1></el-tag>
    </el-row>

    <el-table
        :data="school.records"
        @row-click="click"
        style="width: 100%">
      <el-table-column
          prop="schoolName"
          label="学校名称"
      >
      </el-table-column>
      <el-table-column
          prop="schoolProviceid"
          label="学校位置"
      >
      </el-table-column>
      <el-table-column
          label="是985？">
        <template slot-scope="props">
          {{ getTrue(props.row.schoolNine) }}
        </template>
      </el-table-column>
      <el-table-column
          prop="schoolTwo"
          label="是211？">
        <template slot-scope="props">
          {{ getTrue(props.row.schoolTwo) }}
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        background
        :current-page="school.current"
        :page-size="school.size"
        @current-change="change_School"
        layout="prev, pager, next"
        :total="school.total"
    >
    </el-pagination>

    <el-row>
      <div id="myChart">
      </div>
    </el-row>

    <el-dialog :title="nowSchool.schoolName" :visible.sync="dialogVisible">
      <el-row>
        <el-col :span="8">
          <el-image>
          </el-image>
        </el-col>

        <el-col :span="16">
          <el-row class="dialog">
            学校地址:<el-tag>{{ nowSchool.schoolAddress }}</el-tag>
          </el-row>
          <el-row class="dialog">
            学校官网地址:<el-tag>{{ nowSchool.schoolUrl }}</el-tag>
          </el-row>
          <el-row class="dialog">
            学校邮箱:<el-tag>{{ nowSchool.schoolEmail }}</el-tag>
          </el-row>
          <el-row class="dialog">
            学校电话:<el-tag>{{ nowSchool.schoolTelephone }}</el-tag>
          </el-row>
          <el-row class="dialog">
            学校所属:<el-tag>{{ nowSchool.schoolBelong }}</el-tag>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="dialog">
        <el-col :span="6">
          <div>博士点{{nowSchool.schoolBoshi}}</div>
        </el-col>
        <el-col :span="6">
          <div>硕士点{{nowSchool.schoolShuoshi}}</div>
        </el-col>
        <el-col :span="6">
          <div>重点学科{{nowSchool.schoolXueke}}</div>
        </el-col>
        <el-col :span="6">
          <div>重点实验室{{nowSchool.schoolShiyan}}</div>
        </el-col>

      </el-row>
      <el-row class="introduce">
       <p> 学校简介:{{nowSchool.schoolIntroduce}}</p>
      </el-row>
      <el-row class="dialog">
        <el-tabs v-model="activeName" type="card" >
          <el-tab-pane label="招生简章" name="first">用户管理</el-tab-pane>
          <el-tab-pane label="专业目录" name="second">配置管理</el-tab-pane>
          <el-tab-pane label="参考书籍" name="third">角色管理</el-tab-pane>
          <el-tab-pane label="分数线" name="fourth">定时任务补偿</el-tab-pane>
          <el-tab-pane label="录取名单" name="five">定时任务补偿</el-tab-pane>
        </el-tabs>
      </el-row>
    </el-dialog>

  </div>
</template>

<script>
import {getSchool, getArea} from "@/api/course"

export default {
  name: "School",
  components: {},
  data() {
    return {
      one: 0,
      level: "全部",
      province: [],
      school: [],
      nowProvince: "北京市",
      dialogVisible: false,
      nowSchool: "",
      activeName:"first"
    }
  },
  props: {},
  mounted() {
    this.Area();
    this.change_School(1);
    this.Chart();
  },
  methods: {
    click(row, column, event) {
      this.dialogVisible = true;
      this.nowSchool = row
    },

    Chart() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      myChart.setOption({
        title: {text: '学校信息'},
        tooltip: {},
        xAxis: {
          data: ["衬衫", "羊毛衫", "雪纺衫", "裤子", "高跟鞋", "袜子"]
        },
        yAxis: {},
        series: [{
          name: '销量',
          type: 'bar',
          data: [5, 20, 36, 10, 10, 20]
        }]
      });
    },
    // current 当前页数
    change_School(current) {
      getSchool(this.nowProvince, current, this.level, this.one).then(result => {
        this.school = result.data.school;
      }).catch(error => {
        this.$message({
          type: "error",
          message: error.message,
          duration: 2 * 1000
        });
      })
    },

    Area() {
      getArea("402881ea3f5b1d14013f5b1fdc080006").then(result => {
        this.province = result.data.area;
      }).catch(error => {
        this.$message({
          type: "error",
          message: error.message,
          duration: 2 * 1000
        })
      })
    },
    getTrue(number) {
      return number === 1 ? "是" : "否"
    }
  }
}
</script>

<style scoped>
.p1{
  margin-bottom: 20px;
}
.dialog{
  margin-bottom: 5px;
  text-align: left;
}
.introduce{
  margin: 10px;
  padding: 5px;
  text-align: left;
}
.school {
  width: 100%;
  height: 100%;
}
/*.school .item {*/
/*  margin-bottom: 10px;*/
/*}*/
#myChart {
  height: 28.125rem;
  width: 43.75rem;
}
</style>
